@import "../../../assets/styles/common/colors.scss";
.transfer-modal-container {
  submit-btn {
    width: 100%;
    height: 40px;
    border: 0;
    color: $pure-white;
  }
  .invalid-feedback {
    font-size: 14px;
  }

  .f-12 {
    font-size: 12px;
  }
}
