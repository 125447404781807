@import "../../../assets/styles/common/colors.scss";

.wrap-search {
  padding-block-end: 16px;

  &.bb {
    border-bottom: solid $transparent-black;
  }

  &.search-input {
    padding: 6px 10px;
  }
}

.providers-main {
  padding-block: 16px;

  &__instruction {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $spn-750-color;
  }

  &__list {
    height: 45vh;
    overflow-y: auto;
  }

  &__footer {
    border-top: 1px solid $transparent-black;
    // height: calc(60vh - 42vh);
    padding: 16px;

    .note {
      background: $spn-050-color;
      border: 1px solid $gray-100;
      border-radius: 3px;
      padding: 12px;
      margin-bottom: 12px;
      display: flex;
      align-items: flex-start;
      font-size: 12px;
    }
  }
}

.provider-item {
  display: block;
  position: relative;

  label {
    padding: 12px;
    width: 100%;
    display: block;
    text-align: left;
    color: $blue-902;
    background-color: $pure-white;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    margin-bottom: 0;

    color: $pure-black;
    background: $pure-white;
    border: 2px solid $gray-100;
    border-radius: 6px;

    &:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

    &:after {
      width: 12px;
      height: 12px;
      content: "";
      border: 2px solid $gray-105;
      background-color: $pure-white;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 14px;
      top: 35%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
  }

  input:checked~label {
    border: 2px solid $primary-color;

    &.active {
      border: 1px solid $gray-100 !important;

      &:after {
        display: inline-block;
        content: "\a0";
        background: $spg-500-color !important;
        border-color: $spg-500-color !important;
        border: 1px solid $pure-white;
        box-shadow: inset 0 0 0 1px $pure-white, 0 0 0 1px $spg-500-color !important;
      }
    }

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: $spg-450-color;
      border-color: $spg-450-color;
      display: inline-block;
      content: "\a0";
      background: $primary-color;
      border-color: $primary-color;
      border: 1px solid $pure-white;
      box-shadow: inset 0 0 0 1px $pure-white, 0 0 0 1px $primary-color;
    }
  }

  input:disabled~label {
    border: 1px solid $gray-100;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      display: inline-block;
      content: "\a0";
      background: $gray-100;
      border-color: $gray-100;
      border: 1px solid $pure-white;
      box-shadow: inset 0 0 0 1px $pure-white, 0 0 0 1px $gray-100;
    }
  }

  input {
    width: 12px;
    height: 12px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 14px;
    top: 35%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}