@import "assets/styles/bootstrap-override.scss";
@import "~vgg-ui-react/dist/styles/index.scss";
@import "assets/styles/common/colors.scss";
@import "assets/styles/common/mixins.scss";

* {
  scrollbar-width: thin;
  scrollbar-color: $spb-600-color $spn-200-color;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* width */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $spn-200-color;
  border-radius: 5px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: $spb-600-color;
  border-radius: 5px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: $spb-800-color;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-gray {
  background-color: $gray-060;
}

.pagination-container {
  .pagination-bar {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .pagination-item {
    font-size: 14px !important;

    &.selected {
      color: $primary-color;
      background-color: $blue-900 !important;
    }
  }
}

.pagination-nav {
  font-size: 14px !important;
  padding-block: 1rem;
}

.shimmer-line {
  width: 100%;
  height: 8px;
  align-self: center;
  border-radius: 8px;
}

@mixin fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;
}

@mixin fade-out {
  opacity: 0;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade_in {
  @include fade-in;
}

.fade_out {
  @include fade-out;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $spn-000-color !important;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: $spn-000-color !important;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: $spn-050-color !important;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
  --bs-table-accent-bg: $spn-050-color !important;
}

.table td {
  border-top: none;
  border-right: 1px solid $spn-000-color;
}

.table > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table thead {
  background-color: $blue-901;
  // background-color: hsl(221, 90%, 95%);
}

.bb-100 {
  border-bottom: 1px solid $dark-020;
}

.vh-40 {
  height: 40vh;
}

.table-wrapper {
  border: 1px solid $gray-100;
  box-shadow: 0px 0px 3px $dark-020;
  border-radius: 8px;

  .border-bottom {
    border-bottom: 1px solid $gray-100 !important;

    .page-title {
      @include text(600, 1.125rem, 24px, $pure-black);
      letter-spacing: 0.25px;
    }
  }

  .pagination-container {
    padding: 0 !important;

    .pagination-container {
      border-bottom: 0em !important;
    }
  }
}

.no-bb {
  border-bottom: none !important;
}

.close_size {
  font-size: 1.3rem;
}

.no-bt {
  border-top: none !important;
}

pointer {
  cursor: pointer;
}

.action-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  width: 100%;
}

.awaiting.modal-dialog {
  // margin-right: 26% !important;
  right: -104px !important;
}

@media screen and (max-width: 808px) {
  .awaiting.modal-dialog {
    // margin-right: 26% !important;
    right: -28px !important;
  }
}
