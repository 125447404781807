@import "../../../assets/styles/common/colors.scss";
@import "../../../assets/styles/common/colors.scss";
@import "../../../assets/styles/common/mixins.scss";

.bb {
  border-bottom: 1px solid $spn-050-color;
}
.view-account-form-container {
  .header{
    .cancel{
      background-color: $spn-050-color;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      cursor: pointer;
      border-radius: 8px;
     }
     border-bottom: 1px solid $spn-100-color;
     padding-bottom: 16px;
}
   .title{
     margin: 24px 0 8px 0;
    @include text(700, 22px, normal, $black);
   }
   .desc {
    margin-bottom: 24px;
    @include text(400, 14px, 16px, $spn-500-color);
  }
   .detail {
    @include text(600, 16px, 24px, $spn-900-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      cursor: pointer;
    }
  }
  .details {
    border-radius: 8px;
    border: 1px solid $spn-100-color;
    background: $spn-050-color;
    padding: 20px 16px;
    margin-bottom: 24px;
    .view-items{
      display: flex;
      justify-content: space-between;
      margin-top: 32px;
    }
    .view-title {
      @include text(500, 14px, normal, $spn-500-color);
    }
    .view-value {
      @include text(600, 14px, normal, $spn-900-color);
    }
  }
}