@import "~vgg-ui-react/dist/styles/partials";

.onboard-wrapper {
  .card-section {
    background: #f8faff;
    border: 1px solid #d7dce0;
    border-radius: 4px;

    .content-paragraph {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.048px;
      color: #3a434b;
    }

    .footnote {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      /* or 114% */

      letter-spacing: 0.4px;

      /* SPN/SPN 900 */

      color: #0d0f11;
    }
  }
}
