@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.search-container {
  width: 300px;
  position: relative;
  i {
    position: absolute;
    top: 32%;
    right: 24px;
    color: #24292e;
  }
}
.virtual-account-table {
  .option {
    cursor: pointer;
  }
  .cursor {
    cursor: pointer;
  }
}
.options {
  margin: 0 25px;
}
.more-options {
  position: absolute;
  right: 48px;
  background: $white;
  border: 1px solid $gray-100;
  border-radius: 4px;
  z-index: 100000;
  min-width: 150px;
  text-align: left;
  @include text(400, 14px, 16px, $spb-900-color);
  .option {
    padding: 8px 16px;
  }
  .option::nth-child(even) {
    background-color: $spn-050-color;
  }
}
