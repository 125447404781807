.about-page {
  .content-paragraph {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    letter-spacing: 0.2px;

    color: #1c1c1c;
  }

  .side-paragraph {
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 200% */

    letter-spacing: 0.2px;

    color: #000000;
    .labels {
      font-weight: 500;
    }

    .primary {
      color: #175fff;
    }
  }

  .linked {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
