@import "../../assets/styles/common/colors.scss";

.selected {
  background-color: $pure-white !important;

  &.disable-row {
    cursor: unset !important;
  }
}

.country-wrapper {
  max-height: 578px;
  overflow-y: auto;
  position: relative;

  .active-country {
    background-color: $blue-900 !important;
  }
}

.search-wrapper {
  padding: 10px 0;
  position: sticky;
  top: 0;
  background-color: $pure-white;
}

.custom-control-label::before {
  top: 0.1rem;
  left: -1.5rem;
  width: 1rem;
  height: 1rem;
  background-color: $pure-white;
  border: 1.5px solid $blue-500;
  border-radius: 2px !important;
}

.custom-radio {
  .custom-control-input:checked~.custom-control-label::before {
    background-color: $pure-white;
    border: 1.5px solid $blue-500;
  }

  .custom-control-label:hover.custom-control-label::before {
    box-shadow: 0px 2px 5px $blue-shadow;
  }

  .custom-control-label::after {
    top: 0.4rem;
    left: -1.25rem;
    width: 0.5rem;
    height: 0.5rem;
  }

  .custom-control-input:checked~.custom-control-label::after {
    background-image: none;
    background-color: $blue-500;
    border-radius: 50%;
    border: 1.5px solid $pure-white;
  }

  .custom-control-input:disabled~.custom-control-label::before {
    background-color: $gray-070;
    border: 1.5px solid $gray-080;
  }

  .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: $gray-070;
  }

  .custom-control-input:disabled:checked~.custom-control-label::after {
    background-color: $gray-160;
    background-image: none;
    border-radius: 50%;
    border: 1.5px solid $gray-070;
  }
}

.custom-control-label {
  margin-bottom: 0.5rem;
  padding-left: 5px;
}

.custom-checkbox {
  .custom-control-input:checked~.custom-control-label::before {
    background-color: $pure-white;
    border: 1.5px solid $blue-500;
    // border-radius: 2px !important;
  }

  .custom-control-label:hover.custom-control-label::before {
    box-shadow: 0px 2px 5px $blue-shadow;
  }

  .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='25.412' height='19.855' viewBox='0 0 25.412 19.855'%3e%3cpath id='check' d='M22.6,3.8,8,19.4,1.4,11.7,0,12.9l7.9,9.3L24,5.1Z' transform='translate(0.703 -3.095)' fill='%23007bff' stroke='%23007bff' stroke-width='1'/%3e%3c/svg%3e ");
  }

  .custom-control-label::after {
    top: 0;
    left: -1.6rem;
    width: 1.2rem;
    height: 1.2rem;
  }

  .custom-control-input:disabled~.custom-control-label::before {
    background-color: $gray-070;
    border: 1.5px solid $gray-080;
  }

  .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: $gray-070;
  }

  .custom-control-input:disabled:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='25.412' height='19.855' viewBox='0 0 25.412 19.855'%3e%3cpath id='check' d='M22.6,3.8,8,19.4,1.4,11.7,0,12.9l7.9,9.3L24,5.1Z' transform='translate(0.703 -3.095)' fill='%239ca0b1' stroke='%239ca0b1' stroke-width='1'/%3e%3c/svg%3e ");
  }
}

.country-list {
  padding: 8px 10px;
  cursor: pointer;
  font-size: 0.8em;

  &.enabled:hover {
    background-color: $gray-110;
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.status-key {
  height: 10px;
  width: 10px;

  &.available {
    background-color: $gray-100;
  }

  &.pending {
    background-color: $spo-100-color;
  }

  &.approved {
    background-color: $spg-100-color;
  }
}