.summary {
  display: flex;
  border: 1px solid #d7dce0;
  padding: 16px;
  height: 120px;

  .kyc {
    flex: 1;
    padding: 0 16px;
    border-right: 1px solid #d7dce0;
    .icon {
      border-radius: 50%;
      color: #175fff;
      width: 32px;
      height: 32px;
      background: rgba(23, 95, 255, 0.1);
    }
  }

  .users {
    flex: 1;
    border-right: 1px solid #d7dce0;
    padding: 0 16px;
    .icon {
      border-radius: 50%;
      color: #175fff;
      width: 32px;
      height: 32px;
      background: rgba(23, 95, 255, 0.1);
    }
  }

  .currency {
    flex: 1;
    padding: 0 16px;
    .icon {
      border-radius: 50%;
      color: #175fff;
      width: 32px;
      height: 32px;
      background: rgba(23, 95, 255, 0.1);
    }
  }
}

.kyc-reports {
  width: 676px;
}

.search-container {
  width: 300px;
  position: relative;
  i {
    position: absolute;
    top: 25%;
    right: 12px;
    color: #24292e;
  }
}

.DateRangePicker {
  &__CalendarSelection {
    background-color: #175fff;
    border: 1px solid #0654fe;
  }
  &__Date {
    &--is-highlighted {
      &:hover {
        // background-color: black;
        border-color: green;
      }
    }
  }
}

.chargeback-content {
  border: 1px solid #D7DCE0;
/* Shadow */

box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
border-radius: 8px;
.action {
  color: #175fff;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
}


@media only screen and (max-width: 680px) {
  .kyc-reports {
    width: 100%;
  }
}
