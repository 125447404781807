@import "../../assets/styles/common/colors.scss";

.custom-spinner {
  svg {
    animation: rotate 500ms infinite;
    transform-origin: 50% 50%;
    transform-box: fill-box;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .waiting-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    color: $spn-500-color;
  }
}