@import "../../../assets/styles/common/colors.scss";
@import "../../../assets/styles/common/colors.scss";
@import "../../../assets/styles/common/mixins.scss";

.account-modal-container {
  .submit-btn, .back-btn {
    width: 100%;
    height: 40px;
    border: 0;
    margin-top: 20px;
  }
  .submit-btn {
    color: $pure-white;
  }
  .back-btn {
    border: 1px solid $spn-200-color;
    border-radius: 4px;
    @include text(600, 16px, 24px, $spn-600-color);
  }
  .invalid-feedback {
    font-size: 14px;
  }

  .f-12 {
    font-size: 12px;
  }
  .desc {
    margin-bottom: -20px;
    margin-top: 5px;
    @include text(400, 14px, 16px, $spn-500-color);
  }
  .success-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    height: 400px;
    .success-text {
      margin: 16px 0 5px 0;
     @include text(600, 20px, 30px, $black);
    }
    .success-msg {
      @include text(400, 14px, 22px, $spn-500-color);
     }
  }
  .loading-content {
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .spinner-border-lg, .spinner-border, .text-primary {
       width: 80px;
       height: 80px;
    }
    .submitting {
      margin: 25px 0 16px 0;
      @include text(400, 14px, 17px, $spn-700-color);
    }
    .please-wait {
      @include text(600, 16px, 16px, $black);
    }
  }
}
