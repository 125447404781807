@import "../../assets/styles/common/colors.scss";

.sp-navlink {
  padding: 0.5rem !important;
  color: $gray-500 !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  cursor: pointer;

  &.active {
    border-width: 80%;
    color: $primary-color !important;

    &::after {
      display: block;
      clear: both;
      content: "";
      position: relative;
      left: 0;
      bottom: 0;
      max-width: 250px;
      height: 1px;
      width: 100%;
      /* or 100px */
      border-bottom: 2px solid $primary-color !important;
      margin: 0 auto;
      padding: 4px 0px;
    }
  }
}