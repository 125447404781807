.c-dashboardInfo {
  margin-bottom: 15px;
}
.c-dashboardInfo .wrap {
  background: #ffffff;
  // box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
  // border-radius: 4px;
  // text-align: center;
  position: relative;
  overflow: hidden;
  padding: 20px;
  height: 100%;
  border: 1px solid rgba(215, 220, 224, 0.8);
  border-radius: 3px;
}
.c-dashboardInfo__title,
.c-dashboardInfo__subInfo {
  font-weight: 500;
  font-size: 0.75em;
  line-height: 16px;
  color: #777777;

  .icon {
    border-radius: 50%;
    color: #175fff;
    width: 32px;
    height: 32px;
    background: rgba(23, 95, 255, 0.1);
  }
}
.c-dashboardInfo span {
  display: block;
}
.c-dashboardInfo__count {
  font-weight: 600;
  font-size: 1.25em;
  line-height: 16px;
  color: #111111;
}
// .c-dashboardInfo .wrap:after {
//   display: block;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 8px;
//   background: #175fff;
//   content: "";
// }

.c-dashboardInfo__title svg {
  color: #d7d7d7;
  margin-left: 5px;
}
.MuiSvgIcon-root-19 {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
}
