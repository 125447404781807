@import "~vgg-ui-react/dist/styles/partials";
@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";


.accounts-page-container {
  ._search-container {
    width: 300px;
    position: relative;
    input {
      background-color: rgba(240, 241, 243, 1);
      padding-left: 2rem;
    }
    i {
      position: absolute;
      top: 30%;
      left: 12px;
      color: $spg-660-color;
      width: max-content;
    }
  }
  .view {
    cursor: pointer;
  }
}
