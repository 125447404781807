@import "../../assets/styles/common/colors.scss";

.c-dashboardInfo {
  margin-bottom: 15px;
}

.c-dashboardInfo .wrap {
  background: $pure-white;
  position: relative;
  overflow: hidden;
  padding: 20px;
  height: 100%;
  border: 1px solid $transparent-gray-100;
  border-radius: 3px;
}

.c-dashboardInfo__title,
.c-dashboardInfo__subInfo {
  font-weight: 500;
  font-size: 0.75em;
  line-height: 16px;
  color: $gray-500;

  .icon {
    border-radius: 50%;
    color: $primary-color;
    width: 32px;
    height: 32px;
    background: $blue-901;
  }
}

.c-dashboardInfo span {
  display: block;
}

.c-dashboardInfo__count {
  font-weight: 600;
  font-size: 1.25em;
  line-height: 16px;
  color: $dark-180;
}

.c-dashboardInfo__title svg {
  color: $gray-150;
  margin-left: 5px;
}

.MuiSvgIcon-root-19 {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
}