@import "../../../assets/styles/common/colors.scss";

.wrap-search {
  padding-block-end: 16px;
}

.countries-main {
  padding-block: 16px;

  &__instruction {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $spn-750-color;
  }

  &__list {
    height: 63vh;
    overflow-y: auto;
  }
}