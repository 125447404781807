.success-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #24292e;
}

.success-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin-top: 0;
}
