@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.settlement-page-container {
  ._search-container {
    width: 300px;
    position: relative;

    input {
      background-color: $light-100;
      padding-left: 2rem;
    }

    i {
      position: absolute;
      top: 30%;
      left: 12px;
      color: $dark-200;
      width: max-content;
    }
  }

  .action-btn {
    color: $primary-color
  }
}