@import "../../assets/styles/common/colors.scss";


.transfers-container {
  .search-container {
    width: 300px;
    position: relative;

    input {
      padding-right: 2rem;
    }

    i {
      position: absolute;
      top: 25%;
      right: 20px;
      color: $spn-800-color;
      width: max-content;
    }
  }

  .transfer-btn {
    width: 150px;
    height: 40px;
    border: 0;
  }
}